body {
  position: relative;

  #main {
    padding-bottom: 75px;
    padding-top: 100px;
    min-height: 100vh;
  }

  @include media-breakpoint-up(lg) {
    &.has-sidebar #main {
      margin-left: $navdrawer-width;
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  padding: 2px;
  background: $body-bg;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  margin: 2px 0;
  border-radius: 10px;
  background: $border-color;
}

::-webkit-scrollbar-corner {
  background: $body-bg;
  border: 0;
}
